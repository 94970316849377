<template>
  <v-form ref="form" v-model="valid">
    <base-dialog
      :show="show"
      :cancel-text="'閉じる'"
      :save-text="'差し戻しを実行する'"
      :disabled-save-button="!valid"
      @cancel="onCancel"
      @save="onSave"
    >
      <template #title>
        差し戻し
      </template>
      <template #content>
        <v-container>
          <v-row>
            <v-col>
              <base-text-area
                :counter="2000"
                :label="'差し戻し理由'"
                :outlined="true"
                :persistent-placeholder="true"
                :placeholder="'差し戻し理由を2,000字以内で入力してください'"
                :rules="[maxLength(2000), required]"
                :value="comment"
                @input="onInput($event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </base-dialog>
  </v-form>
</template>

<script>
import BaseDialog from '@/components/atoms/BaseDialog.vue';
import BaseTextArea from '@/components/atoms/BaseTextArea.vue';
import FormRulesMixin from '@/mixins/FormRulesMixin';

export default {
  name: 'RevokeDialog',
  components: { BaseDialog, BaseTextArea },
  mixins: [FormRulesMixin],
  props: {
    cancelText: {
      type: String,
      default: 'キャンセル'
    },
    questId: {
      type: String,
      default: '',
      require: true
    },
    saveText: {
      type: String,
      default: 'OK'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: false,
      comment: '' // 差し戻し理由
    };
  },
  methods: {
    /** キャンセルボタン押下時の処理 */
    onCancel(e) {
      // モーダルを閉じる時にフォームリセット
      this.$refs.form.reset();
      this.$emit('cancel', e);
    },
    /** アクション実行ボタン押下時の処理 */
    async onSave(e) {
      try {
        await this.$store.dispatch('quests/revoke', { questId: this.questId, comment: this.comment });
        this.$emit('revoke', this.comment);
      } finally {
        this.$emit('cancel', e);
      }
    },
    onInput (comment) {
      this.comment = comment;
    }
  }
};
</script>
