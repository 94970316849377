<template>
  <div>
    <v-container class="mb-6 mt-10" style="max-width: 1000px">
      <div class="text-start mb-5">クエスト詳細</div>

      <!-- クエスト進行状況遷移（発注者にだけ表示） -->
      <template v-if="quest.client.token === me.token && challengerList.length > 0">
        <v-row>
          <v-col cols="12" md="12">
            <v-card
              class="outset rounded-xl px-2 pt-3 pb-4"
              :style="styles.app"
            >
              <v-card-title>
                <div style="border-left: 5px solid #fa9f00;" class="ps-3">クエスト遂行中のユーザー</div>
              </v-card-title>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="challengerListHeader"
                    :items="challengerList"
                    :loading="dataTableLoading.contract"
                    :loading-text="'データを読み込んでいます'"
                    :style="styles.app"
                    item-key="id"
                    hide-default-footer
                  >
                    <!-- ニックネーム -->
                    <template v-slot:[`item.challenger.nickname`]="{ item }">
                      <span
                        style="cursor: pointer;"
                        @click="onSelectedClient(item.challenger)"
                      >
                        <v-avatar
                          class="me-1"
                          :color="getProfileImgByToken(item.challenger.token) ? 'white' : 'blue-grey lighten-1'"
                          size="20"
                        >
                          <img v-if="getProfileImgByToken(item.challenger.token)" :src="getProfileImgByToken(item.challenger.token)" />
                          <v-icon v-else color="white" size="20">mdi-account</v-icon>
                        </v-avatar>
                        <span class="link">
                          {{ item.challenger.nickname }}
                        </span>
                      </span>
                    </template>

                    <!-- 進行状況の進捗バー -->
                    <template #[`item.step`]="{ item }">
                      <div class="" style="">
                        <div style="">
                          <v-progress-circular
                              :value="getStepLinear(item.step)"
                              :rotate="-90"
                              :width="16"
                              color="primary"
                            >
                              <div class="caption" style=" position: absolute; color: rgb(68, 63, 53);cursor: default; font-weight: bold;">{{ getStepLinear(item.step) }}%</div>
                          </v-progress-circular>
                        </div>
                      </div>
                    </template>
                    <!-- 進行状況ページへ -->
                    <template #[`item.uuid`]="{ item }">
                      <router-link
                        class="text-decoration-underline"
                        :to="{ name: 'ContractDetail', params: { contractId: item.uuid } }"
                      >
                        詳細を見る
                      </router-link>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <!--
        承認/差し戻し（承認者にだけ表示）
      -->
      <template v-if="quest.status === questStatus.waiting.value">
        <v-row v-if="me.token != quest.client.token && me.isApprover">
          <v-col>
            <v-card
              class="rounded-xl px-2 pt-3 pb-4"
              height="100%"
              :style="styles.app"
              style="border: 3px #eb3d5c solid;"
              flat
            >
              <v-card-text :style="styles.app">
                <span
                  class="font-weight-bold"
                  style="color: #eb3d5c !important;"
                >
                  ・内容に問題がなければ「クエストを公開する」をクリックしてください。<br />
                  ・クエスト公開前に「報酬」の修正が可能です。報酬を修正する場合は報酬欄の<v-icon color="blue darken-3">mdi-fountain-pen-tip</v-icon>をクリックしてください。<br />
                  ・差し戻したい場合は「差し戻す」をクリックして理由を記載してください。<br />
                </span>
              </v-card-text>

              <div class="text-center mt-3">
                <base-button
                  :class-name="'py-6 mr-4 font-weight-bold font-white'"
                  :color="'#eb3d5c'"
                  :outset="false"
                  :width="'180'"
                  @click="onShowRevokeDialog"
                >
                  <template v-slot:text>差し戻す</template>
                </base-button>

                <!-- ボタンの間の間隔を開ける -->
                <div v-if="$vuetify.breakpoint.smAndDown" class="my-5" />

                <base-button
                  :class-name="'py-6 mr-4 font-weight-bold font-white'"
                  :color="'success'"
                  :outset="false"
                  :width="'180'"
                  @click="onRelease"
                >
                  <template v-slot:text>クエストを公開する</template>
                </base-button>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card
              class="rounded-xl px-2"
              height="100%"
              :style="styles.app"
              style="border: 3px #f3c95e solid; background-color:"
              flat
            >
              <v-card-text :style="styles.app">
                <span
                  class="font-weight-bold"
                >
                  現在審査中です。<br />
                  審査通過後「クエストを探す」に公開されます。
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <!-- 差し戻されている場合 -->
      <v-row v-if="latestRevoke">
        <v-col>
          <v-card
            class="rounded-xl px-2"
            height="100%"
            :style="styles.app"
            style="border: 3px #f3c95e solid; background-color:"
            flat
          >
            <v-card-text :style="styles.app">
              <span class="font-weight-bold">
                このクエストは「{{ latestRevoke.userRevoked.fullname }}」さんによって差し戻されました
              </span>
              <base-text-area
                :auto-grow="false"
                :class-name="'mt-3'"
                :label="'差し戻し理由'"
                :outlined="true"
                :readonly="true"
                :value="latestRevoke.comment"
              />

              <!-- クエストの発注者にのみ更新ボタン表示 -->
              <div
                v-if="quest.client.token === me.token"
                class="text-center"
              >
                <base-button
                  :class-name="'py-6 mr-4 font-weight-bold font-white'"
                  :color="'orange darken-1'"
                  :outset="false"
                  @click="onEdit"
                >
                  <template v-slot:text>クエスト内容を修正する</template>
                </base-button>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <!-- クエストタイトル -->
        <v-col cols="12" md="12">
          <v-card
            flat
            :style="styles.app"
          >
            <v-card-title class="text-h5 font-weight-bold">
              {{ quest.title }}
              <v-chip
                class="ms-5"
                color="primary"
                small
                v-text="quest.category.name"
              />
            </v-card-title>
            <v-card-text>
              <div class="caption">
                {{ quest.client.department.name }}
              </div>
              <div @click.prevent.stop="onSelectedClient(quest.client)">
                <span style="cursor: pointer;">
                  <v-avatar
                    class="me-1"
                    :color="getProfileImgByToken(quest.client.token) ? 'white' : 'blue-grey lighten-1'"
                    size="20"
                  >
                    <img v-if="getProfileImgByToken(quest.client.token)" :src="getProfileImgByToken(quest.client.token)" />
                    <v-icon v-else color="white" size="20">mdi-account</v-icon>
                  </v-avatar>
                  <span class="link">
                    {{ quest.client.nickname }}
                  </span>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="12">
          <v-row class="text-center">
            <!-- 報酬 -->
            <v-col cols="12" md="3" sm="6">
              <primary-card
                :class-name="'mb-3'"
              >
                <template #title>
                  報酬
                  <!-- 編集可能アイコンは「審査中」のみ表示 -->
                  <v-btn
                    v-if="quest.status === questStatus.waiting.value && !editableReward"
                    icon color="blue darken-3"
                    @click="editableReward = true"
                  >
                    <v-icon>mdi-fountain-pen-tip</v-icon>
                  </v-btn>
                </template>
                <template #content>
                  <base-text-field
                    v-if="editableReward"
                    :class-name="'mt-7'"
                    :maxlength="8"
                    :label="'報酬額'"
                    :suffix="'ポイント'"
                    :value="quest.reward"
                    @blur="editableReward=false"
                    @input="onInput({ reward: $event })"
                    @keypress="onNumberInput($event)"
                  />
                  <div
                    v-else
                    class="text-h4 font-weight-bold red--text text--lighten-1"
                  >
                    <div>
                      {{ intcomma(quest.reward) }}
                      <div class="text-h6" :class="$vuetify.breakpoint.smAndDown ? 'd-inline-block' : ''">ポイント</div>
                    </div>
                  </div>
                </template>
              </primary-card>
            </v-col>

            <!-- 契約人数 -->
            <v-col cols="12" md="3" sm="6">
              <primary-card
                :class-name="'mb-3'"
              >
                <template #title>
                  契約人数
                </template>
                <template #content>
                  <div class="text-h4 font-weight-bold">
                    {{ intcomma(quest.contractNumber) }}<span class="text-h5">人</span>
                    <div class="caption" :class="$vuetify.breakpoint.smAndDown ? 'd-inline-block' : ''">(募集人数: {{ intcomma(quest.recruitmentNumbers) }}人)</div>
                  </div>
                </template>
              </primary-card>
            </v-col>

            <!-- 応募期限 -->
            <v-col cols="12" md="3" sm="6">
              <primary-card
                :class-name="'mb-3'"
              >
                <template #title>
                  応募期限
                </template>
                <template #content>
                  <span class="text-h5 font-weight-bold">{{ dateFormat(quest.deadline) }}</span>
                </template>
              </primary-card>
            </v-col>

            <!-- 完了期限 -->
            <v-col cols="12" md="3" sm="6">
              <primary-card
                :class-name="'mb-3'"
              >
                <template #title>
                  完了期限
                </template>
                <template #content>
                  <span class="text-h5 font-weight-bold">{{ dateFormat(quest.delivery) }}</span>
                </template>
              </primary-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- クエストの内容 -->
      <v-row>
        <v-col>
          <v-card
            class="outset rounded-xl px-10 py-5"
            :style="styles.app"
          >
            <v-card-title>
              <div style="border-left: 5px solid #fa9f00;" class="ps-3">依頼内容</div>
            </v-card-title>
            <v-card-text
              :style="styles.app"
              style="white-space: pre-wrap; word-wrap: break-word"
            >
              {{ quest.content }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!--
        クエスト応募ボタンの表示条件
        ・ユーザーがクエスト依頼者自身でない（サーバー側で判定し、結果はshowApplyButton）
        ・ユーザーがクエストに応募したことがない（サーバー側で判定し、結果はshowApplyButton）
        ・契約人数が募集人数を満たしていない
      -->
      <v-row v-if="quest.showApplyButton && quest.recruitmentNumbers !== quest.contractNumber">
        <v-col class="text-center">
          <base-button
            :class-name="'py-6 mr-4 font-weight-bold font-white'"
            :color="'orange darken-1'"
            @click="onApply"
          >
            <template v-slot:text>このクエストに応募する</template>
          </base-button>
        </v-col>
      </v-row>

      <!-- 応募者一覧 -->
      <v-row class="mt-10">
        <v-col>
          <v-card
            class="outset rounded-xl px-10 py-5"
            :style="styles.app"
          >
            <v-card-title>
              <div style="border-left: 5px solid #fa9f00;" class="ps-3">応募者一覧</div>
            </v-card-title>
            <v-card-text :style="styles.app">
              <v-data-table
                :headers="applyListHeader"
                :items="applyList.results"
                :no-data-text="'クエスト応募者がいません'"
                :style="styles.app"
                item-key="id"
                hide-default-footer
              >
                <!-- ニックネーム -->
                <template v-slot:[`item.userApplied.nickname`]="{ item }">
                  <span
                    style="cursor: pointer;"
                    @click="onSelectedClient(item.userApplied)"
                  >
                    <v-avatar
                      class="me-1"
                      :color="getProfileImgByToken(item.userApplied.token) ? 'white' : 'blue-grey lighten-1'"
                      size="20"
                    >
                      <img v-if="getProfileImgByToken(item.userApplied.token)" :src="getProfileImgByToken(item.userApplied.token)" />
                      <v-icon v-else color="white" size="20">mdi-account</v-icon>
                    </v-avatar>
                    <span class="link">
                      {{ item.userApplied.nickname }}
                    </span>
                  </span>
                </template>

                <!-- 応募日時フォーマット -->
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ dateFormat(item.createdAt, 'YYYY年MM月DD日 HH時MM分') }}
                </template>

                <!--
                  ・「契約する」ボタンは発注者にだけ表示
                  ・「契約する」ボタンは未契約の応募者にだけ表示
                -->
                <template v-slot:[`item.isContract`]="{ item }">
                  <base-button
                    v-if="me.token === item.quest.client.token && !item.isContract"
                    :color="'success'"
                    :outset="false"
                    @click="onContract(item.userApplied)"
                  >
                    <template v-slot:text>この応募者と契約する</template>
                  </base-button>
                  <div v-if="item.isContract" class="font-weight-bold d-flex align-center justify-center primary--text">
                    <v-icon color="primary">mdi-party-popper</v-icon>&nbsp;クエスト契約者
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- 差し戻しダイアログ -->
    <revoke-dialog
      :quest-id="quest.uuid"
      :show="showRevokeDialog"
      @cancel="onShowRevokeDialog"
      @revoke="onRevoked"
    />

    <!-- 公開プロフィールモーダル -->
    <public-profile-modal
      :show="showPublicProfile"
      :user-info="selectedClient"
      @cancel="onShowPublicProfile"
      @on-initialize="onInitialize"
    />
  </div>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BaseButton from '@/components/atoms/BaseButton';
import BaseTextArea from '@/components/atoms/BaseTextArea';
import BaseTextField from '@/components/atoms/BaseTextField';
import PrimaryCard from '@/components/organisms/PrimaryCard';
import { QUEST_STATUS } from '@/assets/constants';
import PublicProfileModal from '@/components/organisms/PublicProfileModal';
import RevokeDialog from '@/components/organisms/RevokeDialog';

export default {
  name: 'Detail',
  components: {
    BaseButton,
    BaseTextArea,
    BaseTextField,
    PrimaryCard,
    PublicProfileModal,
    RevokeDialog
  },
  mixins: [AppMethodsMixin, AppStyleMixin],
  data() {
    return {
      quest: {
        title: '',
        reward: '', // 報酬
        recruitmentNumbers: '', // 募集人数
        deadline: '', // 応募期限
        delivery: '', // 完了期限
        content: '', // 依頼内容
        contractNumber: '', // 契約人数
        client: {
          fullname: '',
          department: {
            name: ''
          }
        },
        department: {
          name: ''
        },
        category: {
          name: ''
        },
        showApplyButton: false // 「応募する」ボタン判定用
      },
      applyListHeader: [
        {
          align: 'start',
          text: 'ニックネーム',
          value: 'userApplied.nickname'
        },
        {
          align: 'start',
          text: '部署',
          value: 'userApplied.department.name'
        },
        {
          align: 'start',
          text: '応募日時',
          value: 'createdAt'
        },
        {
          align: 'start',
          text: '',
          value: 'isContract'
        }
      ],
      applyList: {
        results: []
      },
      editableReward: false,
      questStatus: QUEST_STATUS, // constant
      showRevokeDialog: false, // 差し戻しダイアログ表示
      latestRevoke: null, // 差し戻しレコード（差し戻しされている場合のみ）
      challengerListHeader: [
        {
          align: 'start',
          text: 'ニックネーム',
          value: 'challenger.nickname'
        },
        {
          align: 'start',
          text: '部署',
          value: 'challenger.department.name'
        },
        {
          align: 'start',
          text: '進行状況',
          value: 'step'
        },
        {
          align: 'start',
          text: '',
          value: 'uuid'
        }
      ],
      challengerList: [],
      dataTableLoading: {
        contract: false
      },
      selectedClient: null,
      showPublicProfile: false // 公開プロフィールをモーダル表示
    };
  },
  computed: {
    // ログインユーザー情報取得
    me() {
      return this.$store.getters['users/getMe'];
    }
  },
  methods: {
    onInput (quest) {
      this.quest = { ...this.quest, ...quest };
    },
    /** クエスト詳細取得 */
    async getDetail() {
      this.quest = await this.$store.dispatch('quests/retrieve', this.$route.params.questId);
    },
    /** クエストの応募者一覧取得 */
    getApplyList() {
      this.$store.dispatch('applyQuests/applies', this.$route.params.questId).then(
        responseData => {
          this.applyList = responseData;
        }
      );
    },
    /** 応募者との契約 */
    async onContract(userApplied) {
      if (confirm(`${userApplied.nickname}さんと契約しますか?`)) {
        this.showLoadingOverlay(async () => {
          await this.$store.dispatch('quests/contract', { questId: this.$route.params.questId, userId: userApplied.token });
          await this.getApplyList(); // 応募者一覧再取得
          this.getChallengerList(); // 契約者一覧再取得
          this.quest.contractNumber += 1; // 契約人数をインクリメント
        });
      }
    },
    /** 最新の差し戻しレコード取得 */
    getLatestRevoke() {
      this.$store.dispatch('quests/latestRevoke', this.$route.params.questId).then(
        responseData => {
          this.latestRevoke = responseData;
        }
      );
    },
    /** クエストの契約者を一覧取得 */
    getChallengerList() {
      this.dataTableLoading.contract = true;
      this.$store.dispatch('quests/challengers', this.$route.params.questId).then(
        responseData => {
          this.challengerList = responseData;
        }
      ).finally(() => {
        this.dataTableLoading.contract = false;
      });
    },
    /** クエスト公開 */
    async onRelease() {
      if (confirm('クエストを公開しますか?')) {
        this.quest = await this.$store.dispatch('quests/release', { questId: this.$route.params.questId, reward: this.quest.reward });
        this.quest.showApplyButton = true;
      }
    },
    /** クエストへの応募処理 */
    async onApply() {
      if (confirm('このクエストに応募しますか?')) {
        await this.$store.dispatch('quests/apply', this.$route.params.questId);
        this.quest.showApplyButton = false; // 応募するボタン非表示
        this.getApplyList(); // 応募者一覧再取得
      }
    },
    /** クエスト修正画面へ */
    onEdit() {
      this.$store.commit('quests/setCurrentQuest', this.quest);
      this.$router.push({ name: 'EditQuest', params: { questId: this.$route.params.questId } });
    },
    onSelectedClient(selectedClient) {
      this.selectedClient = selectedClient;
      this.onShowPublicProfile();
    },
    /** 発注者プロフィールをモーダル表示 */
    onShowPublicProfile() {
      this.showPublicProfile = !this.showPublicProfile;
    },
    /** 差し戻しダイアログの表示/非表示 */
    onShowRevokeDialog() {
      this.showRevokeDialog = !this.showRevokeDialog;
    },
    /** 差し戻し実行後の処理 */
    onRevoked(comment) {
      this.quest.status = this.questStatus.revoke.value;
      this.latestRevoke = {
        comment: comment,
        userRevoked: {
          token: this.me.token,
          fullname: this.me.fullname
        }
      };
    },
    getStepLinear(step) {
      return step / 5;
    },
    onInitialize() {
      this.showLoadingOverlay(async () => {
        this.getChallengerList(); // 契約者を一覧取得
        this.getLatestRevoke(); // 差し戻しレコード取得
        this.getApplyList(); // 応募者一覧取得
        await this.getDetail(); // 詳細取得
      });
    }
  },
  mounted() {
    this.onInitialize();
  },
  beforeRouteUpdate(to, from, next) {
    this.onInitialize();
    next();
  }
};
</script>
