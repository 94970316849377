<!-- ダイアログのベース -->
<template>
  <div class="text-center">
    <v-dialog
      v-model="_show"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          <slot name="title" />
        </v-card-title>

        <v-card-text>
          <slot name="content" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- キャンセル -->
          <v-btn
            v-if="!hiddenCancelButton"
            class="py-6 font-weight-bold"
            text
            :color="'blue-grey lighten-2'"
            @click="onCancel"
          >
            {{ cancelText }}
          </v-btn>

          <!-- OK -->
          <v-btn
            v-if="!hiddenSaveButton"
            class="py-6 font-weight-bold"
            text
            color="primary"
            :disabled="disabledSaveButton"
            @click="onSave"
          >
            {{ saveText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    cancelText: {
      type: String,
      default: 'キャンセル'
    },
    saveText: {
      type: String,
      default: 'OK'
    },
    show: {
      type: Boolean,
      default: false
    },
    hiddenCancelButton: {
      type: Boolean,
      default: false
    },
    hiddenSaveButton: {
      type: Boolean,
      default: false
    },
    disabledSaveButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set() {
        this.$emit('cancel');
      }
    }
  },
  methods: {
    /** キャンセルボタン押下時の処理 */
    onCancel(e) {
      this.$emit('cancel', e);
    },
    /** アクション実行ボタン押下時の処理 */
    onSave(e) {
      this.$emit('save', e);
    }
  }
};
</script>
